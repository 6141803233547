import React from "react"
import {Typography, Grid,} from '@material-ui/core'
import { makeStyles} from '@material-ui/core/styles';

import Layout from "../../components/layout"
import { useStaticQuery, graphql } from "gatsby";
import SubProgram from './subprogramTemplate'

import {
  Carousel,
  Container,
  Row,
  Col,
  //CardGroup,
  Card,
  CardDeck,
  Button,
  CardColumns

} from 'react-bootstrap';

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(2),
    margin: theme.spacing(1),
    width:"70%",
    //color:"#0000",
    //sansSerif, 
    fontSize: "46px", 
    fontWeight: "100", 
    lineHeight: "50px", 
    letterSpacing: "1px", 
    padding: "0 0 40px", 
    //borderBottom: "double #555"
    fontFamily:"Arial Black"
    
    },
    spacingTextDescription:{
      marginBottom: theme.spacing(5),
      marginTop: theme.spacing(5),
    },
    font:{
      fontFamily:"Montserrat",
      width:"100%",
      textAlign: 'justify'

    },
    bodyText:{
      fontFamily:"Montserrat",
      textAlign: 'justify'
    },
    text:{
      //width:"70%",
      alignText:"center"
      
      
    }
}));

const Programs = () => {
  const classes = useStyles();   
  
  const programs= useStaticQuery(graphql`query{
    allRestApiApiPagesShowall {
      edges {
        node {
          data {
            pages {
              content {
                programs {
                  content
                  description
                  img
                  text
                  title
                  subProgram {
                    content
                    img
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }`)
 console.log(programs.allRestApiApiPagesShowall.edges[0].node.data.pages[12].content.programs)//data.allRestApiApiProgramsShowall.edges[0].node.data)
  return(
    <Layout>
      <Typography className={classes.margin} variant="h5" align ="center">Programas Base del SITIMM</Typography>
      <div className={classes.text}>
      <p className={classes.font}>
      El SITIMM promueve la integración y unidad de los trabajadores afiliados para brindar un servicio sindical grato en cuanto a la gestión y atención de sus requerimientos sociales y económicos, derechos laborales y formación cultural y sindical que les proporcione oportunidades de mejora en sus niveles de vida y de trabajo.
      </p>
      <p className={classes.font}>
      Es así, que por mandato de la Asamblea, el Comité Ejecutivo del SITIMM, ha implementado 5 programas que contribuyen a fortalecer la economía, la calidad de vida y el desarrollo humano de sus afiliados.
      </p>

      </div>
      <CardColumns>
      { 
       programs.allRestApiApiPagesShowall.edges[0].node.data.pages[12].content.programs.map( subProgram => {
         return( 
          <SubProgram subProgram={subProgram} />
          )
       })}

      </CardColumns>
    </Layout>
  )
}

export default Programs



//https://cdn1-www.thefashionspot.com/assets/uploads/gallery/all-the-september-2019-magazine-covers-we-loved-and-hated/8p4xb5zltg945blp.jpg